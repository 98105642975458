import { mixpanel } from '@/modules/mixpanel'
import { LightMode, Spinner, VStack } from '@chakra-ui/react'
import {
  ArrowUpRightIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'

import { LogoGrid } from '../LogoGrid/LogoGrid'

const suggestedQuestions = [
  '2024 T2DM guidelines',
  'Outpatient tx for pneumonia',
]

export const HeroSectionQuestion = () => {
  const [question, setQuestion] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const router = useRouter()

  const askQuestion = async (question: string) => {
    setIsLoading(true)
    void router.push(
      `/auth/new?redirectedFrom=${encodeURIComponent(
        `/ai?question=${question}`,
      )}`,
    )
    setTimeout(() => setIsLoading(false), 1000)
  }

  const handleQuestionSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!question) {
      return
    }
    mixpanel.track('landing_page_question_asked', { question })
    void askQuestion(question)
  }

  const handleSuggestedQuestionClick = (question: string) => {
    mixpanel.track('landing_page_question_suggestions_clicked', { question })
    setQuestion(question)
    if (inputRef.current) {
      inputRef.current.focus()
    }
    void askQuestion(question)
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  return (
    <>
      <div className="relative isolate bg-white pt-6">
        <div className="sm:pt-6">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-3xl text-center">
              <h1 className="mb-10 mt-12 text-4xl font-semibold tracking-tighter text-gray-900 sm:text-5xl md:text-6xl lg:mt-24 lg:text-7xl lg:leading-[1.0625]">
                <p>AI-powered medical knowledge</p>
              </h1>

              <VStack align="stretch" mt={8} spacing={4}>
                <form onSubmit={handleQuestionSubmit}>
                  <div className="relative mx-auto w-full max-w-xl rounded-xl">
                    <div className="pointer-events-none absolute left-4 top-1/2 -translate-y-1/2 transform">
                      {isLoading ? (
                        <Spinner mt={1.5} size="sm" />
                      ) : (
                        <MagnifyingGlassIcon className="h-5 w-5 text-gray-600" />
                      )}
                    </div>
                    <input
                      className={
                        'w-full rounded-xl border border-gray-300 bg-white py-3 pl-12 pr-24 text-black shadow-xl shadow-gray-300/20 placeholder:text-gray-500 focus:border-blue-600 focus:shadow-xl focus:shadow-blue-600/10 focus:outline-none focus:ring-0'
                      }
                      onChange={(e) => {
                        setQuestion(e.target.value)
                      }}
                      placeholder="Ask a question..."
                      ref={inputRef}
                      required
                      type="text"
                      value={question}
                    />
                    <button
                      className="absolute right-2 top-1/2 -translate-y-1/2 transform rounded-lg bg-blue-600 px-3 py-1.5 text-[15px] font-medium text-white hover:bg-blue-700"
                      disabled={isLoading}
                      type="submit"
                    >
                      Ask Free
                    </button>
                  </div>
                </form>
                <div className="mt-8">
                  <div className="flex flex-wrap justify-center gap-2">
                    {suggestedQuestions.map((q, index) => (
                      <button
                        className="flex items-center rounded-full border border-gray-300 bg-white px-3 py-1 text-sm text-gray-800 transition-colors duration-200 hover:bg-gray-100"
                        key={index}
                        onClick={() => handleSuggestedQuestionClick(q)}
                      >
                        {q}
                        <ArrowUpRightIcon className="ml-1 h-4 w-4" />
                      </button>
                    ))}
                  </div>
                </div>
              </VStack>
            </div>

            {/* Logo Cloud*/}
            <div className="py-16 lg:py-32">
              <LightMode>
                <LogoGrid />
              </LightMode>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
