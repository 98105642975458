import Head from 'next/head'

interface MetaHeadInterface {
  canonical?: string
  description: string
  image?: string
  title: string
  twitterCard?: string
  type?: 'article' | 'website'
}

export const HeadMeta = ({
  canonical,
  description,
  image = '/marketing/metaimage.jpg',
  title,
  twitterCard,
  type = 'website',
}: MetaHeadInterface) => {
  return (
    <Head>
      <title>{title}</title>
      {canonical && <link href={canonical} rel="canonical" />}

      <meta content={title} property="og:title" />

      {description && (
        <>
          <meta content={description} key="desc" name="description" />
          <meta content={description} property="og:description" />
        </>
      )}
      <meta content={image} property="og:image" />
      <meta content={type} property="og:type" />

      {twitterCard && <meta content={twitterCard} name="twitter:card" />}
    </Head>
  )
}
