import type {
  Document,
  DocumentParents,
  DocumentType,
} from '../types/documents'
import type { MonographSubsectionName } from '../types/monographs'

import { monographSubsectionNames } from '../constants/monographs'

export function resourceToSlugIdentifier(args: {
  binaryId: string
  slug?: null | string
}) {
  if (!args.slug) {
    return `${args.binaryId}`
  }
  return `${args.slug}-${args.binaryId}`
}

export function resourceIdFromSlug(link: string) {
  return (link ?? '').split('-').pop() ?? ''
}

export function resourceIdToSlug(link: string) {
  return (link ?? '').split('-')[0] ?? ''
}

export const generateLink = (
  collectionName: DocumentType,
  id: string,
  parents?: DocumentParents,
  document?: Document,
  slug?: string,
) => {
  // abstracts, chunks
  if (collectionName === 'abstracts' || collectionName === 'chunks') {
    return document?.metadata.referenceURI ?? '#'
  }

  if (collectionName === 'recommendations') {
    if (!parents) {
      return null
    }

    const diseaseId = parents.find((x) => x.type === 'diseases')?.id ?? null
    const topicId = parents.find((x) => x.type === 'topics')?.id ?? null

    if (!diseaseId || !topicId) {
      return null
    }

    return `/diseases/${resourceToSlugIdentifier({
      binaryId: diseaseId,
      slug,
    })}#guidelines-topic-${topicId}`
  }

  if (collectionName === 'topics') {
    if (!parents) {
      return null
    }

    const topicId = id

    if (!topicId) {
      return null
    }

    const diseaseId = parents.find((x) => x.type === 'diseases')?.id ?? null

    if (diseaseId !== null) {
      return `/diseases/${resourceToSlugIdentifier({
        binaryId: diseaseId,
        slug,
      })}#guidelines-topic-${topicId}`
    }

    const presentationId =
      parents.find((x) => x.type === 'presentations')?.id ?? null

    if (presentationId !== null) {
      return `/findings/${resourceToSlugIdentifier({
        binaryId: presentationId,
        slug,
      })}`
    }

    return null
  }

  if (collectionName === 'aspects') {
    const diseaseId = parents?.[0]?.id
    if (!diseaseId) return null
    return `/diseases/${resourceToSlugIdentifier({
      binaryId: diseaseId,
      slug,
    })}#guidelines-aoc-${id}`
  }

  if (collectionName === 'dosages') {
    const drugId = parents?.[0]?.id ?? ''
    return `/drugs/${resourceToSlugIdentifier({
      binaryId: drugId,
      slug,
    })}#section-${id}`
  }

  if (collectionName === 'drugs' || collectionName === 'monographs') {
    const drugId = id
    return `/drugs/${resourceToSlugIdentifier({
      binaryId: drugId,
      slug,
    })}`
  }

  if (
    monographSubsectionNames.includes(collectionName as MonographSubsectionName)
  ) {
    const drugId = parents?.[0]?.id ?? ''
    return `/drugs/${resourceToSlugIdentifier({
      binaryId: drugId,
      slug,
    })}#section-${id}`
  }

  // Fix for presentations and drug collections
  if (collectionName === 'presentations') {
    collectionName = 'findings'
  } else if (collectionName.toString() === 'drug') {
    collectionName = 'drugs'
  }

  return `/${collectionName}/${resourceToSlugIdentifier({
    binaryId: id,
    slug,
  })}`
}
