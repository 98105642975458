import type { NextPage } from 'next'

import { HeadMeta } from '@/components/Marketing/HeadMeta'
import { AIFeed } from '@/components/Marketing/Home/AIFeed'
import { HeroSectionQuestion } from '@/components/Marketing/Home/HeroSectionQuestion'
import { Navbar } from '@/components/Marketing/Navbar'
import { getHostUrl } from '@/utils/getHostUrl'
import { useFeatureFlagEnabled } from 'posthog-js/react'

const IndexPage: NextPage = () => {
  const showHomeAIFeed = useFeatureFlagEnabled('home-ai-feed')

  return (
    <>
      <Head />
      <Navbar showSignInInsteadOfBurger={true} variant="minimal" />
      <HeroSectionQuestion />
      {showHomeAIFeed && <AIFeed />}
    </>
  )
}

const Head = () => {
  return (
    <HeadMeta
      canonical={getHostUrl() + '/'}
      description="Pathway is an accurate and efficient point-of-care reference tool that gives you rapid access to evidence-based guideline summaries, interactive algorithms"
      title="Pathway - Home"
    />
  )
}

export default IndexPage
