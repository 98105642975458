export function JohnHopkinsLogo(props: { fill: string; size: number }) {
  const height = (props.size * 49.7) / 261
  return (
    <svg
      aria-hidden="true"
      height={height}
      role="img"
      style={{ maxWidth: '100%' }}
      viewBox="0 0 260.7 49.663"
      width={props.size}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill={props.fill}>
        <g data-name="university">
          <path d="M91.569 39.44a10.338 10.338 0 0 0-.269-2.95.881.881 0 0 0-.714-.3l-.355-.028a.259.259 0 0 1 .026-.343c.544.029 1.07.043 1.627.043.6 0 .982-.014 1.5-.043a.248.248 0 0 1 .03.343l-.344.028a.833.833 0 0 0-.712.344 12.831 12.831 0 0 0-.2 2.907v1.613a4.951 4.951 0 0 1-1 3.4 3.756 3.756 0 0 1-2.724 1.043 4.055 4.055 0 0 1-2.664-.785c-.755-.627-1.112-1.667-1.112-3.364v-3.364c0-1.483-.029-1.726-.856-1.8l-.354-.028c-.086-.058-.059-.3.026-.343.712.029 1.2.043 1.768.043s1.056-.014 1.752-.043c.086.043.115.285.03.343l-.342.028c-.827.071-.856.314-.856 1.8v3.081c0 2.294.712 3.8 2.893 3.8 2.067 0 2.852-1.622 2.852-3.791Z"></path>
          <path d="M110.198 42.848c0 .427 0 2.124.043 2.494a.254.254 0 0 1-.269.158c-.171-.243-.584-.742-1.825-2.152l-3.308-3.764c-.387-.443-1.355-1.611-1.654-1.926h-.029a3.651 3.651 0 0 0-.072.926v3.107a11.148 11.148 0 0 0 .259 2.953c.085.156.37.242.728.269l.441.045a.259.259 0 0 1-.03.355 36.935 36.935 0 0 0-1.667-.044c-.6 0-.984.016-1.483.044a.261.261 0 0 1-.029-.355l.384-.045c.33-.043.557-.128.628-.285a13.707 13.707 0 0 0 .185-2.937v-4.119a1.31 1.31 0 0 0-.313-1.027 1.557 1.557 0 0 0-.885-.341l-.24-.03a.25.25 0 0 1 .026-.357c.6.043 1.355.043 1.611.043a4.577 4.577 0 0 0 .657-.043 19.608 19.608 0 0 0 2.438 3.136l1.382 1.555c.985 1.1 1.683 1.9 2.355 2.582h.026a1.414 1.414 0 0 0 .059-.6v-3.05a10.074 10.074 0 0 0-.285-2.95c-.085-.131-.314-.216-.885-.285l-.239-.03c-.1-.085-.086-.314.029-.357.656.029 1.141.043 1.683.043.611 0 .984-.014 1.467-.043a.25.25 0 0 1 .03.357l-.2.03c-.457.069-.742.184-.8.3a11.544 11.544 0 0 0-.215 2.937Z"></path>
          <path d="M120.818 38.029c0-1.512-.029-1.754-.87-1.825l-.357-.029c-.086-.059-.056-.315.029-.358.712.03 1.2.043 1.8.043.569 0 1.054-.013 1.767-.043.085.043.114.3.029.358l-.355.029c-.842.07-.871.313-.871 1.825v5.076c0 1.511.029 1.71.871 1.808l.355.045c.085.056.056.313-.029.355-.713-.026-1.2-.043-1.767-.043-.6 0-1.085.016-1.8.043a.275.275 0 0 1-.029-.355l.357-.045c.841-.1.87-.3.87-1.808Z"></path>
          <path d="M132.364 37.373c-.328-.8-.542-1.1-1.155-1.169l-.259-.029a.242.242 0 0 1 .03-.358c.413.03.87.044 1.483.044s1.125-.014 1.726-.044a.253.253 0 0 1 .029.358l-.215.029c-.542.07-.655.155-.67.27a17.426 17.426 0 0 0 .712 2 147.586 147.586 0 0 0 2.027 4.918c.439-.941 1.04-2.408 1.368-3.165.412-.969 1.084-2.58 1.325-3.194a1.5 1.5 0 0 0 .129-.557c0-.1-.144-.214-.643-.27l-.255-.029a.249.249 0 0 1 .029-.358c.4.03.941.044 1.481.044.472 0 .915-.014 1.384-.044a.287.287 0 0 1 .03.358l-.428.029a.913.913 0 0 0-.743.5 34.761 34.761 0 0 0-1.6 3.279l-.771 1.752c-.571 1.315-1.24 2.966-1.483 3.723a.29.29 0 0 1-.154.043.6.6 0 0 1-.216-.043 16.688 16.688 0 0 0-.656-1.9Z"></path>
          <path d="M149.527 38.002c0-1.485-.029-1.7-.87-1.8l-.229-.03c-.085-.059-.056-.314.029-.357.614.029 1.1.043 1.683.043h2.681a19.193 19.193 0 0 0 1.924-.043 16.18 16.18 0 0 1 .229 1.9.28.28 0 0 1-.357.026c-.214-.67-.342-1.168-1.084-1.354a6.7 6.7 0 0 0-1.384-.086h-1.026c-.424 0-.424.03-.424.572v2.851c0 .4.043.4.467.4h.829a5.3 5.3 0 0 0 1.21-.086c.174-.058.273-.144.344-.5l.115-.584a.28.28 0 0 1 .368.014c0 .344-.059.9-.059 1.443 0 .512.059 1.056.059 1.366a.277.277 0 0 1-.368.016l-.131-.555a.6.6 0 0 0-.439-.542 4.674 4.674 0 0 0-1.1-.072h-.825c-.424 0-.467.014-.467.387v2.009c0 .756.043 1.24.267 1.481.173.171.472.329 1.728.329a4.159 4.159 0 0 0 1.825-.214 3.566 3.566 0 0 0 1.011-1.382.259.259 0 0 1 .357.1 12.2 12.2 0 0 1-.642 1.979 188.279 188.279 0 0 0-3.821-.043h-1.283c-.613 0-1.1.016-1.941.043a.274.274 0 0 1-.028-.354l.469-.045c.815-.07.886-.285.886-1.78Z"></path>
          <path d="M164.976 38.016c0-1.355-.043-1.6-.626-1.67l-.457-.055a.24.24 0 0 1 .016-.358c.795-.072 1.782-.115 3.176-.115a5.05 5.05 0 0 1 2.383.429 2.125 2.125 0 0 1 1.181 2 2.636 2.636 0 0 1-1.78 2.379c-.071.085 0 .229.07.344a15.268 15.268 0 0 0 2.867 3.791 1.709 1.709 0 0 0 .984.4.119.119 0 0 1 .014.2 2.215 2.215 0 0 1-.627.072c-1.213 0-1.941-.358-2.95-1.8-.373-.528-.957-1.512-1.4-2.152a1.017 1.017 0 0 0-1.011-.456c-.642 0-.67.013-.67.314v1.8c0 1.494.028 1.667.856 1.78l.3.045a.277.277 0 0 1-.026.354 39.642 39.642 0 0 0-1.7-.043c-.6 0-1.115.016-1.782.043a.273.273 0 0 1-.029-.354l.354-.045c.827-.1.856-.285.856-1.78Zm1.171 2.037a1.14 1.14 0 0 0 .043.472c.043.043.257.07.982.07a2.378 2.378 0 0 0 1.469-.372 2.041 2.041 0 0 0 .712-1.766 2.1 2.1 0 0 0-2.283-2.2c-.881 0-.924.056-.924.457Z"></path>
          <path d="M182.206 45.5a4.458 4.458 0 0 1-2.225-.528 6.654 6.654 0 0 1-.384-1.995c.073-.1.286-.129.344-.043a2.593 2.593 0 0 0 2.452 2.123 1.619 1.619 0 0 0 1.784-1.638 2.139 2.139 0 0 0-1.171-1.995l-1.352-.885a3.041 3.041 0 0 1-1.542-2.452c0-1.355 1.056-2.454 2.907-2.454a5.578 5.578 0 0 1 1.328.184 1.827 1.827 0 0 0 .5.085 6.279 6.279 0 0 1 .257 1.742c-.056.085-.285.128-.355.043a1.866 1.866 0 0 0-1.938-1.613 1.5 1.5 0 0 0-1.7 1.584 2.236 2.236 0 0 0 1.211 1.752l1.141.714a3.208 3.208 0 0 1 1.782 2.737c0 1.568-1.183 2.638-3.038 2.638"></path>
          <path d="M194.936 38.029c0-1.512-.028-1.754-.87-1.825l-.357-.029c-.086-.059-.056-.315.029-.358.714.03 1.2.043 1.8.043.571 0 1.054-.013 1.769-.043.085.043.114.3.026.358l-.358.029c-.838.07-.867.313-.867 1.825v5.076c0 1.511.03 1.71.867 1.808l.358.045c.087.056.059.313-.026.355-.716-.026-1.2-.043-1.769-.043-.6 0-1.083.016-1.8.043a.275.275 0 0 1-.029-.355l.357-.045c.842-.1.87-.3.87-1.808Z"></path>
          <path d="M210.765 43.133c0 1.494.026 1.71.851 1.78l.459.045c.085.056.055.312-.03.355-.813-.028-1.3-.044-1.867-.044s-1.069.016-1.983.044a.249.249 0 0 1 0-.355l.514-.045c.813-.07.885-.285.885-1.78v-6.374c0-.443 0-.456-.429-.456h-.783a2.922 2.922 0 0 0-1.753.357 2.6 2.6 0 0 0-.642.985.279.279 0 0 1-.372-.1 14.777 14.777 0 0 0 .542-2.126.363.363 0 0 1 .272 0c.086.456.558.441 1.214.441h5.775c.768 0 .9-.028 1.11-.4.071-.026.229-.014.255.043a8.6 8.6 0 0 0-.213 2.169c-.056.113-.3.113-.372.026a1.837 1.837 0 0 0-.354-1.04 2.845 2.845 0 0 0-1.657-.357h-1.011c-.427 0-.411.013-.411.484Z"></path>
          <path d="M227.012 43.105c0 1.523.085 1.752.856 1.808l.544.045a.278.278 0 0 1-.03.355 58.843 58.843 0 0 0-1.941-.043c-.6 0-1.11.015-1.867.043a.277.277 0 0 1-.03-.355l.445-.045c.825-.085.851-.285.851-1.808v-.728a2.877 2.877 0 0 0-.453-1.766l-1.683-3.281c-.486-.941-.7-1.012-1.126-1.083l-.4-.071a.264.264 0 0 1 .029-.358 25.8 25.8 0 0 0 1.654.043c.656 0 1.168-.014 1.525-.043.128.043.128.272.043.358l-.184.029c-.5.07-.6.142-.6.257a7.176 7.176 0 0 0 .443 1.1c.526 1.054 1.054 2.179 1.612 3.166.439-.758.911-1.584 1.339-2.411a12.933 12.933 0 0 0 .925-1.883c0-.085-.255-.184-.6-.227l-.257-.029a.239.239 0 0 1 .029-.358 21.368 21.368 0 0 0 2.695 0 .241.241 0 0 1 .03.358l-.4.071c-.742.128-1.171 1.027-1.883 2.254l-.9 1.553a3.279 3.279 0 0 0-.672 2.309Z"></path>
        </g>
        <g data-name="shield">
          <path d="M36.09 5.094c-.037-.07-2.249-1.165-2.249-1.165a22.291 22.291 0 0 0-10.68.857h-.012a22.287 22.287 0 0 0-10.678-.857s-2.213 1.095-2.246 1.165l-3.428 7.565a.235.235 0 0 0 .259.351 90.584 90.584 0 0 1 16.1-1.294 90.571 90.571 0 0 1 16.1 1.294.235.235 0 0 0 .26-.351Zm-13.744 5.448c-3.715-.147-10.125-.147-13.339 1l2.285-5.718 1.557-.833-1.654 4.713a21.711 21.711 0 0 1 11.16.475Zm1.567 0v-.363a21.707 21.707 0 0 1 11.159-.475l-1.661-4.71 1.559.833 2.285 5.718c-3.05-1.15-9.461-1.15-13.342-1"></path>
          <path d="m44.406 2.266-.023-.009-.146-.033a101.047 101.047 0 0 0-42.187 0l-.068.014-.064.021A2.578 2.578 0 0 0 0 4.773v3.756C0 35.62 16.765 46.61 21.9 49.333a2.677 2.677 0 0 0 1.261.33 2.6 2.6 0 0 0 1.255-.334c5.124-2.716 21.892-13.71 21.892-40.8V4.773a2.606 2.606 0 0 0-1.905-2.507M22.212 47.774C16.547 44.649 5.17 35.782 2.228 17.605l.138-.029a100.823 100.823 0 0 1 19.845-2.149Zm17.921-17.422-.01.02a41.5 41.5 0 0 1-4 6.641 40.551 40.551 0 0 1-4.006 4.654v-7.324l-4.008-2.667v13.478a33.912 33.912 0 0 1-4 2.621V29.008l4 2.668V22.35l4.008 2.664v-9.2c1.339.118 2.676.257 4.006.428v11.439l4 2.664V16.85c1.282.219 2.557.452 3.826.72l.137.03a46.052 46.052 0 0 1-3.953 12.751M24.1 19.681v-4.254c1.337.012 2.672.046 4 .112v6.809ZM44.8 8.529a57.852 57.852 0 0 1-.418 7 103.24 103.24 0 0 0-42.442.007 57.757 57.757 0 0 1-.419-7.006V4.773a1.088 1.088 0 0 1 .826-1.055l.025-.008a99.54 99.54 0 0 1 41.545-.01l.016.006a1.084 1.084 0 0 1 .867 1.066Z"></path>
          <path d="m36.121 27.68-4.006-2.666v9.328l4.006 2.666Z"></path>
          <path d="M13.642 33.558a7.332 7.332 0 1 0-7.332-7.332 7.341 7.341 0 0 0 7.332 7.332m1.335-1.066a9.709 9.709 0 0 0 1.748-2.664 12.613 12.613 0 0 1 1.807.528 6.4 6.4 0 0 1-3.556 2.135m4.111-2.907a12.972 12.972 0 0 0-2.035-.632 8.986 8.986 0 0 0 .407-2.265h2.566a6.35 6.35 0 0 1-.94 2.9m0-6.717a6.355 6.355 0 0 1 .94 2.9h-2.566a8.977 8.977 0 0 0-.407-2.264 13.09 13.09 0 0 0 2.035-.633m-.555-.773a12.592 12.592 0 0 1-1.807.53 9.681 9.681 0 0 0-1.749-2.664 6.412 6.412 0 0 1 3.556 2.134m-4.429-1.706a9.05 9.05 0 0 1 1.674 2.4 14.635 14.635 0 0 1-1.674.14Zm0 3.469a15.9 15.9 0 0 0 2.028-.183 8.114 8.114 0 0 1 .4 2.09h-2.43Zm0 2.831h2.43a7.94 7.94 0 0 1-.406 2.09 15.564 15.564 0 0 0-2.024-.183Zm0 2.834a15.043 15.043 0 0 1 1.668.14 9 9 0 0 1-1.668 2.4Zm-5.352.835a12.792 12.792 0 0 1 1.81-.529 9.7 9.7 0 0 0 1.753 2.667 6.4 6.4 0 0 1-3.563-2.138m4.427 1.7a9.188 9.188 0 0 1-1.664-2.393 15.323 15.323 0 0 1 1.664-.139Zm0-3.458a15.922 15.922 0 0 0-2.018.181 7.813 7.813 0 0 1-.4-2.088h2.423Zm0-2.831h-2.423a7.848 7.848 0 0 1 .4-2.09 15.693 15.693 0 0 0 2.018.183Zm0-2.834a14.765 14.765 0 0 1-1.664-.139 9.129 9.129 0 0 1 1.664-2.393Zm-.864-2.973a9.681 9.681 0 0 0-1.753 2.667 12.7 12.7 0 0 1-1.808-.532 6.41 6.41 0 0 1 3.562-2.135m-4.119 2.904a13.142 13.142 0 0 0 2.041.633 8.875 8.875 0 0 0-.412 2.259H7.257a6.335 6.335 0 0 1 .939-2.9m-.939 3.821h2.568a8.978 8.978 0 0 0 .406 2.264 13.061 13.061 0 0 0-2.039.633 6.322 6.322 0 0 1-.939-2.9"></path>
        </g>
        <g data-name="johns hopkins">
          <path d="M59.544 10.271c0-3.633-.326-4.027-2.258-4.191l-.817-.063c-.2-.132-.131-.72.066-.818 1.9.064 3.01.1 4.384.1 1.31 0 2.423-.033 3.732-.1.2.1.261.686.066.818l-.491.063c-1.93.265-2 .719-2 4.191v12.144a18.223 18.223 0 0 1-.752 6.347 7.11 7.11 0 0 1-6.446 4.718c-.391 0-1.406-.035-1.406-.688 0-.558.489-1.507 1.177-1.507a3.994 3.994 0 0 1 1.213.195 5.02 5.02 0 0 0 1.374.229 1.4 1.4 0 0 0 1.31-.85c.752-1.539.849-6.447.849-8.215Z"></path>
          <path d="M64.647 17.977a9.545 9.545 0 0 1 9.829-9.747c6.378 0 9.581 4.611 9.581 9.47a9.426 9.426 0 0 1-9.581 9.635c-6.128 0-9.829-4.389-9.829-9.359m16.649.58c0-4.555-2.014-9.47-7.288-9.47-2.873 0-6.6 1.961-6.6 8.008 0 4.084 1.988 9.388 7.426 9.388 3.314 0 6.461-2.485 6.461-7.925"></path>
          <path d="M90.331 17.98c-1.325 0-1.381.056-1.381.886v3.891c0 2.9.138 3.286 1.684 3.451l.8.084c.167.109.11.606-.054.689a88.163 88.163 0 0 0-3.508-.083c-1.186 0-2.125.056-3.2.083a.538.538 0 0 1-.056-.689l.469-.084c1.546-.276 1.6-.552 1.6-3.451v-9.94c0-2.9-.193-3.367-1.629-3.479l-.719-.055c-.166-.11-.109-.607.056-.69 1.353.026 2.292.083 3.478.083 1.078 0 2.016-.026 3.2-.083.166.083.222.58.055.69l-.525.055c-1.6.166-1.655.58-1.655 3.479v3.176c0 .855.055.884 1.381.884h7.9c1.325 0 1.382-.029 1.382-.884v-3.177c0-2.9-.056-3.313-1.685-3.479l-.523-.055c-.167-.11-.113-.607.054-.69 1.269.056 2.209.083 3.341.083 1.077 0 2.016-.026 3.258-.083.163.083.22.58.056.69l-.582.055c-1.6.166-1.655.58-1.655 3.479v9.94c0 2.9.056 3.258 1.655 3.451l.663.084c.166.109.11.606-.056.689a74.346 74.346 0 0 0-3.339-.083c-1.132 0-2.125.026-3.341.083a.532.532 0 0 1-.054-.689l.523-.084c1.685-.276 1.685-.552 1.685-3.451v-3.891c0-.829-.056-.886-1.382-.886Z"></path>
          <path d="M123.05 22.204c0 .829 0 4.114.081 4.831a.494.494 0 0 1-.523.3c-.33-.467-1.132-1.433-3.534-4.168l-6.406-7.289c-.744-.856-2.623-3.12-3.2-3.725h-.057a6.858 6.858 0 0 0-.14 1.792v6.02c0 1.3.028 4.887.5 5.713.167.306.719.471 1.409.526l.856.084a.5.5 0 0 1-.056.689 70.395 70.395 0 0 0-3.229-.083 45.32 45.32 0 0 0-2.872.083.513.513 0 0 1-.056-.689l.747-.084c.634-.083 1.076-.248 1.216-.552.383-.993.357-4.361.357-5.687v-7.976a2.528 2.528 0 0 0-.606-1.988 3 3 0 0 0-1.713-.664l-.469-.054a.484.484 0 0 1 .056-.691c1.16.083 2.622.083 3.119.083a8.758 8.758 0 0 0 1.271-.083c.551 1.409 3.809 5.054 4.721 6.075l2.678 3.008c1.9 2.126 3.256 3.672 4.555 5h.056a2.781 2.781 0 0 0 .11-1.16v-5.91c0-1.3-.028-4.886-.553-5.715-.166-.248-.606-.414-1.712-.552l-.469-.054c-.193-.166-.165-.609.055-.691 1.27.056 2.209.083 3.259.083 1.186 0 1.9-.026 2.842-.083a.484.484 0 0 1 .056.691l-.387.054c-.882.138-1.436.36-1.546.58-.469.994-.413 4.418-.413 5.688Z"></path>
          <path d="M130.348 27.338a8.622 8.622 0 0 1-4.307-1.02 12.684 12.684 0 0 1-.747-3.865c.138-.194.553-.248.664-.082.413 1.406 1.545 4.114 4.748 4.114a3.137 3.137 0 0 0 3.451-3.175 4.147 4.147 0 0 0-2.265-3.867l-2.622-1.714a5.885 5.885 0 0 1-2.986-4.752c0-2.624 2.044-4.748 5.633-4.748a10.749 10.749 0 0 1 2.569.358 3.6 3.6 0 0 0 .963.167 12.015 12.015 0 0 1 .5 3.366c-.11.168-.552.25-.691.085-.358-1.328-1.1-3.121-3.755-3.121-2.706 0-3.286 1.794-3.286 3.067 0 1.6 1.326 2.758 2.348 3.394l2.209 1.381c1.739 1.076 3.451 2.677 3.451 5.3 0 3.036-2.292 5.107-5.88 5.107"></path>
          <path d="M152.613 16.327c-1.57 0-1.636.063-1.636 1.045v4.616c0 3.435.162 3.894 1.995 4.092l.951.1c.2.13.13.719-.068.815-1.766-.064-2.878-.1-4.154-.1-1.409 0-2.522.064-3.8.1a.627.627 0 0 1-.066-.815l.556-.1c1.833-.329 1.9-.657 1.9-4.092V10.205c0-3.436-.229-3.993-1.93-4.123l-.851-.066c-.2-.13-.13-.72.066-.818 1.6.032 2.716.1 4.125.1 1.276 0 2.388-.032 3.8-.1.2.1.262.688.064.818l-.622.066c-1.9.2-1.962.687-1.962 4.123v3.764c0 1.015.066 1.046 1.636 1.046h9.361c1.57 0 1.636-.031 1.636-1.046v-3.764c0-3.436-.066-3.925-2-4.123l-.622-.066c-.2-.13-.13-.72.068-.818 1.5.066 2.617.1 3.959.1 1.277 0 2.391-.032 3.863-.1.2.1.262.688.064.818l-.687.066c-1.9.2-1.964.687-1.964 4.123v11.783c0 3.435.067 3.862 1.964 4.092l.786.1c.2.13.131.719-.067.815a92.124 92.124 0 0 0-3.96-.1c-1.341 0-2.518.031-3.959.1a.63.63 0 0 1-.068-.815l.622-.1c2-.329 2-.657 2-4.092v-4.616c0-.981-.066-1.045-1.636-1.045Z"></path>
          <path d="M168.839 17.977a9.546 9.546 0 0 1 9.828-9.747c6.379 0 9.581 4.611 9.581 9.47a9.425 9.425 0 0 1-9.581 9.635c-6.127 0-9.828-4.389-9.828-9.359m16.648.58c0-4.555-2.013-9.47-7.289-9.47-2.869 0-6.6 1.961-6.6 8.008 0 4.084 1.988 9.388 7.426 9.388 3.314 0 6.46-2.485 6.46-7.925"></path>
          <path d="M193.246 22.756c0 2.9.056 3.258 1.822 3.451l.745.083a.536.536 0 0 1-.055.69 94.256 94.256 0 0 0-3.589-.083c-1.158 0-2.152.026-3.367.083a.534.534 0 0 1-.054-.69l.58-.083c1.6-.22 1.655-.552 1.655-3.451V12.569c0-2.348-.056-2.817-1.3-2.955l-.993-.112a.458.458 0 0 1 .056-.689 43.251 43.251 0 0 1 5.465-.222 9.967 9.967 0 0 1 5.3 1.106 4.77 4.77 0 0 1 2.209 4.14 4.678 4.678 0 0 1-2.762 4.387 9.324 9.324 0 0 1-3.811.827c-.193-.081-.193-.5-.026-.55 2.982-.553 4.058-2.239 4.058-4.64a4.069 4.069 0 0 0-4.444-4.417c-1.463 0-1.492.108-1.492.994Z"></path>
          <path d="M204.733 12.815c0-2.9-.056-3.341-1.655-3.478l-.693-.055c-.165-.112-.108-.609.056-.691 1.353.056 2.235.083 3.478.083 1.077 0 2.016-.026 3.2-.083.165.083.221.58.054.691l-.523.055c-1.6.165-1.658.579-1.658 3.478v3.178c0 .523.056 1.047.361 1.047a1.56 1.56 0 0 0 .771-.3c.387-.33 1.106-1.049 1.409-1.325l2.982-2.953c.523-.5 1.877-1.906 2.152-2.292a.82.82 0 0 0 .2-.443c0-.109-.11-.192-.471-.275l-.745-.166a.462.462 0 0 1 .054-.691c.966.056 2.072.083 3.037.083s1.906-.026 2.732-.083a.51.51 0 0 1 .056.691 6.815 6.815 0 0 0-2.566.773 27.514 27.514 0 0 0-3.868 3.2l-2.485 2.346c-.387.389-.635.636-.635.856 0 .2.166.415.526.856 2.622 3.008 4.748 5.495 6.9 7.759a3.2 3.2 0 0 0 2.1 1.133l.533.083a.479.479 0 0 1-.056.69c-.717-.056-1.474-.084-2.773-.084-1.131 0-2.1.028-3.368.084-.193-.056-.276-.527-.11-.69l.634-.113c.389-.054.663-.137.663-.274 0-.167-.193-.387-.387-.636-.526-.663-1.242-1.408-2.292-2.6l-2.209-2.485c-1.573-1.766-2.016-2.318-2.678-2.318-.413 0-.469.359-.469 1.325v3.562c0 2.9.056 3.285 1.6 3.451l.747.083c.163.11.11.606-.056.69a76.808 76.808 0 0 0-3.368-.084c-1.132 0-2.07.028-3.2.084a.532.532 0 0 1-.056-.69l.553-.083c1.462-.221 1.519-.553 1.519-3.451Z"></path>
          <path d="M223.685 12.872c0-2.927-.054-3.4-1.685-3.534l-.688-.055c-.166-.112-.112-.607.054-.691 1.381.056 2.319.084 3.479.084 1.106 0 2.044-.028 3.424-.084.167.084.221.58.056.691l-.691.055c-1.629.137-1.683.607-1.683 3.534V22.7c0 2.928.054 3.314 1.683 3.508l.691.083c.165.11.11.606-.056.69a79.059 79.059 0 0 0-3.424-.084c-1.16 0-1.994.028-3.373.084a.537.537 0 0 1-.054-.69l.582-.083c1.631-.193 1.685-.58 1.685-3.508Z"></path>
          <path d="M247.568 22.204c0 .829 0 4.114.085 4.831a.5.5 0 0 1-.526.3c-.33-.467-1.132-1.433-3.534-4.168l-6.406-7.289c-.744-.856-2.623-3.12-3.2-3.725h-.054a6.922 6.922 0 0 0-.139 1.792v6.02c0 1.3.028 4.887.5 5.713.167.306.719.471 1.409.526l.856.084a.507.507 0 0 1-.056.689 70.395 70.395 0 0 0-3.229-.083c-1.16 0-1.905.028-2.872.083a.51.51 0 0 1-.056-.689l.747-.084c.634-.083 1.076-.248 1.215-.552.387-.993.358-4.361.358-5.687v-7.976a2.528 2.528 0 0 0-.606-1.988 3 3 0 0 0-1.713-.664l-.469-.054a.484.484 0 0 1 .056-.691c1.16.083 2.622.083 3.119.083a8.758 8.758 0 0 0 1.271-.083c.553 1.409 3.809 5.054 4.719 6.075l2.681 3.008c1.905 2.126 3.256 3.672 4.555 5h.056a2.781 2.781 0 0 0 .11-1.16v-5.91c0-1.3-.028-4.886-.553-5.715-.166-.248-.606-.414-1.71-.552l-.469-.054c-.2-.166-.167-.609.053-.691 1.27.056 2.209.083 3.258.083 1.19 0 1.906-.026 2.843-.083a.484.484 0 0 1 .056.691l-.387.054c-.884.138-1.436.36-1.546.58-.469.994-.415 4.418-.415 5.688Z"></path>
          <path d="M254.82 27.338a8.642 8.642 0 0 1-4.31-1.02 12.825 12.825 0 0 1-.747-3.865c.14-.194.556-.248.664-.082.415 1.406 1.547 4.114 4.75 4.114a3.137 3.137 0 0 0 3.451-3.175 4.151 4.151 0 0 0-2.265-3.867l-2.621-1.714a5.884 5.884 0 0 1-2.981-4.752c0-2.624 2.042-4.748 5.633-4.748a10.75 10.75 0 0 1 2.569.358 3.609 3.609 0 0 0 .963.167 12.015 12.015 0 0 1 .5 3.366c-.113.168-.552.25-.691.085-.358-1.328-1.1-3.121-3.753-3.121-2.708 0-3.288 1.794-3.288 3.067 0 1.6 1.326 2.758 2.346 3.394l2.211 1.381c1.74 1.076 3.451 2.677 3.451 5.3 0 3.036-2.292 5.107-5.88 5.107"></path>
        </g>
      </g>
    </svg>
  )
}
