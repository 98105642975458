import { resourceToSlugIdentifier } from 'p-common/src/utils/links'

import { getHostUrl } from './getHostUrl'

export function getAIResponseShareLink(args: {
  slug?: null | string
  uuid: string
}): string {
  return `${getHostUrl()}/ai/history/${resourceToSlugIdentifier({
    binaryId: args.uuid,
    slug: args.slug,
  })}`
}
