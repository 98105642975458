/* AIRTABLE COLLECTIONS */

export const monographSubsectionCollectionNames = [
  'monographsRenalImpairment',
  'monographsRenalReplacementTherapy',
  'monographsHepaticImpairment',
  'monographsPregnancy',
  'monographsBreastfeeding',
  'monographsHazards',
  'monographsAdverseReactions',
] as const

export const guidelineRelatedCollectionNames = [
  'assessments',
  'gradings',
  'keypoints',
  'organisations',
  'locations',
] as const

export const diseaseRelatedCollectionNames = [
  'associations',
  'backgrounds',
  'findings',
  'probabilities',
  'qualifiers',
] as const

export const drugRelatedCollectionNames = [
  'dosages',
  'frequencies',
  'indications',
  'monographs',
  'mutations',
  'routes',
  'units',
  'pregnancyCategories',
] as const

export const protocolRelatedCollectionNames = [
  'blockTypes',
  'contactMethods',
  'filters',
  'periods',
  'blocks',
  'bundles',
  'criteria',
  'expressions',
] as const

export const miscellaneousCollectionNames = [
  'abbreviations',
  'categories',
  'interventions',
  'factoids',
  'notes',
  'synonyms',
  'tests',
  'references',
] as const

export const secondaryCollectionNamesInRawOnly = [
  'adverseEvents',
  'assistantResponses',
  'assistantResponses1',
  'assistantResponses2',
  'assistantResponses3',
  'diseaseFindingScores',
  'diseaseHandouts',
  'grades',
  'localizations',
  'templates',
  'alternativeSpellings',
] as const

export const secondaryCollectionNamesInParsedOnly = ['aspectsOfCare'] as const

export const secondaryCollectionNamesInRawAndParsed = [
  ...monographSubsectionCollectionNames,
  ...guidelineRelatedCollectionNames,
  ...diseaseRelatedCollectionNames,
  ...protocolRelatedCollectionNames,
  ...drugRelatedCollectionNames,
  ...miscellaneousCollectionNames,
] as const

/* RAW COLLECTIONS */

export const secondaryCollectionNamesInRaw = [
  ...secondaryCollectionNamesInRawAndParsed,
  ...secondaryCollectionNamesInRawOnly,
] as const

/* PARSED COLLECTIONS */

export const secondaryCollectionNamesInParsed = [
  ...secondaryCollectionNamesInRawAndParsed,
  ...secondaryCollectionNamesInParsedOnly,
] as const

/* ALL COLLECTIONS */

export const secondaryCollectionNames = [
  ...secondaryCollectionNamesInRawOnly,
  ...secondaryCollectionNamesInRawAndParsed,
  ...secondaryCollectionNamesInParsedOnly,
] as const
